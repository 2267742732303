import { FC } from 'react';
import { Container } from './styles';
import { LoggedProfile } from '@components/header/logged-profile';

export const Header: FC = () => {
  return (
    <Container>
      <LoggedProfile />
    </Container>
  );
};
