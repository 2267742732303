import { FC } from 'react';
import { Container } from './styles';
import LogOutSvg from '@components/icons/LogOut';

export const LogoutButton: FC = () => {
  const handleLogOut = () => {
    const entitySessionRemoved = new CustomEvent('entitySessionRemoved');
    window.dispatchEvent(entitySessionRemoved);
  };

  return (
    <Container>
      <Container onClick={handleLogOut} data-testid='logout-button'>
        <LogOutSvg width='12' height='12' color='#f00'/>
        <span>Sair</span>
      </Container>
    </Container>
  );
};
