import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, LogoSection, ItemsSection, SwaggerSection } from './styles';
import LogoTrinusSvg from '@components/icons/LogoTrinus';
import { SideBarMenus } from '@components/sidebar/sidebar-menus';
import { useAuth } from '@context/auth-context';

interface SideBarProps {
  title: string;
  options: any;
}

export const SideBar: FC<SideBarProps> = ({ title, options }) => {
  const { permissions, hasPermission } = useAuth();
  const navigate = useNavigate();
  const [allSubMenusDisabled, setAllSubMenusDisabled] = useState<boolean>(true);

  const handleSubMenuItem = (subMenuItem: any, subIndex: number) => {
    let subMenuItemPath: string = subMenuItem.path;
    let subMenuItemEnable: boolean = subMenuItem.enable;
    const enableSubMenu = hasPermission(permissions, subMenuItem);

    if (!enableSubMenu) {
      subMenuItemPath = '';
      subMenuItemEnable = false;
    }

    return {
      title: subMenuItem.title,
      subIndex,
      subMenuItemPath,
      subMenuItemEnable,
    };
  };

  const handleSubMenus = (subMenu: any, index: number) => {
    const subItemsResult: any[] = [];
    let enableMenu: boolean = false;
    const menuItemsResult: any[] = [];
    let subMenuEnable: boolean = subMenu.enable;

    subMenu.subMenus.map((subMenuItem: any, subIndex: number) => {
      subItemsResult.push(handleSubMenuItem(subMenuItem, subIndex));
    });

    subMenuEnable = subItemsResult.some(
      (item: any) => item.subMenuItemEnable === true
    );

    menuItemsResult.push(subMenuEnable);
    enableMenu = menuItemsResult.every(
      (subMenusVisibility: boolean) => subMenusVisibility === false
    );

    if (!enableMenu) {
      setAllSubMenusDisabled(false);
    }

    return {
      subMenuEnable,
      enableMenu,
      index,
    };
  };
  return (
    <Container>
      <LogoSection>
        <h1>{title}</h1>
      </LogoSection>
      <ItemsSection>
        {options.map((item: any, index: number) => (
          <SideBarMenus
            title={item.title}
            item={item}
            key={item.title}
            enable={item.enable}
            handleSubMenuItem={handleSubMenuItem}
            handleSubMenus={handleSubMenus}
            disableSubMenusIcons={allSubMenusDisabled}
          />
        )).sort((firstMenu: any, secondMenu: any) => firstMenu.key.localeCompare(secondMenu.key))}
      </ItemsSection>
      <LogoSection>
        <LogoTrinusSvg />
      </LogoSection>
      <SwaggerSection>
        <h4 onClick={() => navigate('/app/banking/api')}>API Banking - Boletos</h4>
      </SwaggerSection>
    </Container>
  );
};
