import { FC, useState, useContext } from 'react';
import { Menu, SubMenuItem } from './styles';
import * as RiIcons from 'react-icons/ri';
import { GlobalAlert } from '@components/global-alert';
import AlertContext from '@context/alert-context';

interface SideBarMenusProps {
  title: string;
  item: any;
  enable?: boolean;
  handleSubMenuItem?: (item: any, index: number) => any;
  handleSubMenus?: (item: any, index: number) => any;
  disableSubMenusIcons?: boolean;
}

export const SideBarMenus: FC<SideBarMenusProps> = ({
  title,
  item,
  enable,
  handleSubMenuItem,
  handleSubMenus,
  disableSubMenusIcons,
}) => {
  const alert = useContext(AlertContext);

  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [subMenuStates, setSubMenuStates] = useState(
    item.menus?.map(() => false) || []
  );

  const handleSideBarMenus = () => {
    setIsMenuClicked(!isMenuClicked);
    setShowItems(!showItems);
  };

  const handleSideBarSubMenus = (index: number) => {
    const updatedSubMenuStates = [...subMenuStates];
    updatedSubMenuStates[index] = !updatedSubMenuStates[index];
    setSubMenuStates(updatedSubMenuStates);
  };

  const renderSubMenuItem = (subMenuItem: any, subItemIndex: number) => {
    const { title, subMenuItemEnable, subMenuItemPath } =
      handleSubMenuItem(subMenuItem, subItemIndex);
    return (
      <SubMenuItem
        to={subMenuItemPath}
        key={subMenuItem.title}
        $enable={subMenuItemEnable}
      >
        <label>{title}</label>
      </SubMenuItem>
    );
  };

  const renderSubMenus = (subMenu: any, subMenuIndex: number) => {
    const { subMenuEnable, index } = handleSubMenus(subMenu, subMenuIndex);

    return (
      <div key={subMenu.title}>
        <Menu
          to={subMenu.path}
          onClick={() => handleSideBarSubMenus(index)}
          $isClicked={subMenuStates[index]}
          $isSubMenu={true}
          $enable={subMenuEnable}
        >
          <label>{subMenu.title}</label>
          {subMenuEnable ? (
            subMenuStates[index] ? (
              <RiIcons.RiArrowUpSFill />
            ) : (
              <RiIcons.RiArrowDownSFill />
            )
          ) : (
            <></>
          )}
        </Menu>
        {subMenuEnable &&
          subMenuStates[index] &&
          subMenu.subMenus &&
          subMenu.subMenus.map((subMenuItem: any, subIndex: number) =>
            renderSubMenuItem(subMenuItem, subIndex)
          ).sort((firstSubMenu: any, secondSubMenu: any) => firstSubMenu.key.localeCompare(secondSubMenu.key))}
      </div>
    );
  };

  const renderSubMenuIcons = () => {
    if (isMenuClicked) {
      alert.error('Não autorizado!');
    }

    return (
      <>
        <RiIcons.RiArrowDownSFill />
        <GlobalAlert showAlert={alert.showAlert} />
      </>
    );
  };

  return (
    <>
      <Menu
        to={item.path}
        onClick={item.menus && handleSideBarMenus}
        $isClicked={isMenuClicked}
        $isSubMenu={false}
        $enable={enable}
      >
        <label>{title}</label>
        {disableSubMenusIcons && isMenuClicked
          ? renderSubMenuIcons()
          : item.menus &&
            (isMenuClicked ? (
              <RiIcons.RiArrowUpSFill />
            ) : (
              <RiIcons.RiArrowDownSFill />
            ))}
      </Menu>
      {showItems &&
        item.menus &&
        item.menus.map((subMenu: any, index: number) =>
          renderSubMenus(subMenu, index)
        )}
    </>
  );
};
