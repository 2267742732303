import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, ContentContainer, ParcelContainer } from '@pages/styles';
import { Header } from '@components/header';
import { ParcelBanking } from '@components/parcels/parcel-banking';
import { SideBar } from '@components/sidebar';
import { SideBarData } from '@components/sidebar/sideBarData';
import { useAuth } from '@context/auth-context';

export const AppHome: React.FC = () => {
  const authContext = useAuth();

  const { token } = JSON.parse(sessionStorage.getItem('entitySession'));

  useEffect(() => {
    authContext.updatePermissions(token);
  }, [token]);

  return (
    <Container>
      <SideBar title="Backoffice" options={SideBarData} />
      <ContentContainer>
        <Header />
        <ParcelContainer>
          <Routes>
            <Route path="/banking/*" Component={ParcelBanking} />
          </Routes>
        </ParcelContainer>
      </ContentContainer>
    </Container>
  );
};
