import React, { useCallback, useMemo, useState } from 'react';

export enum AlertStatus {
  None = 'NONE',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
}

const AlertContext = React.createContext(null);

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState<AlertStatus>(AlertStatus.None);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const setTimer = useCallback((timeout) => {
    setTimeout(() => {
      setAlert(AlertStatus.None);
      setShowAlert(false);
      setAlertText('');
    }, timeout * 1000 || 5000);
  }, []);

  const showAlertWithType = useCallback(
    (type, text, timeout) => {
      setAlert(type);
      setAlertText(text);
      setShowAlert(true);
      setTimer(timeout);
    },
    [setTimer]
  );

  const handleClearAlert = () => {
    setAlert(AlertStatus.None);
    setShowAlert(false);
    setAlertText('');
  };

  const value = useMemo(
    () => ({
      alert,
      alertText,
      showAlert,
      success: (text: string, timeout: number | undefined) =>
        showAlertWithType(AlertStatus.Success, text, timeout),
      error: (text: string, timeout: number | undefined) =>
        showAlertWithType(AlertStatus.Error, text, timeout),
      warning: (text: string, timeout: number | undefined) =>
        showAlertWithType(AlertStatus.Warning, text, timeout),
      info: (text: string, timeout: number | undefined) =>
        showAlertWithType(AlertStatus.Info, text, timeout),
      clear: handleClearAlert,
    }),
    [alert, alertText, showAlert, showAlertWithType]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export { AlertProvider };
export default AlertContext;
