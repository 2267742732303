import styled from 'styled-components';

export const Container = styled.div`
  min-width: 13rem;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid rgb(11,93,215, 0.5);
  border-top: none;
  padding-top: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;

  @media (max-width: 43rem) {
   min-width: 7rem;
  }
`;

export const ProfileItem = styled.span`
  font-size: 0.8rem;
  font-weight: lighter;
  font-family: 'Asap', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    font-weight: bold;
  }

  @media (max-width: 43rem) {
    font-size: 0.6rem;
  }
`