import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;

export const ParcelContainer = styled.div`
  width: 85vw;
  height: 94vh;
  background-color: #e2e8f0;

  @media (max-width: 66.625rem) {
    width: calc(100vw - 10rem);
  }
`;

export const ContentContainer = styled.div`
  width: 85vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
