import { FC } from 'react';
import { LogoutButton } from '../../logout-button';
import { Container, ProfileItem } from './style';

interface ProfileDataProps {
  fullName: string;
  email?: string;
  accessType: string;
}

export const ProfileData: FC<ProfileDataProps> = ({
  fullName,
  email,
  accessType
}) => {
  return (
    <Container>
      <ProfileItem title={fullName} data-testid='profile-fullName'><span>Nome: </span>{fullName}</ProfileItem>
      {email && <ProfileItem title={email} data-testid='profile-email'><span>Email: </span>{email}</ProfileItem>}
      <ProfileItem data-testid='profile-access-type'><span>Acesso: </span>{accessType}</ProfileItem>
      <LogoutButton />
    </Container>
  )
}