export const SideBarData = [
  {
    title: 'Banking',
    path: '/app',
    enable: true,
    menus: [
      {
        title: 'Acesso API',
        path: '/app',
        enable: false,
        subMenus: [
          {
            title: 'Aplicativos',
            path: '/app/banking/application',
            enable: true,
            key: 'application',
            grants: ['MAINTAIN_ANY'],
          },
          {
            title: 'Usuários/Sharks',
            path: '/app/banking/user',
            enable: true,
            key: 'user',
            grants: ['MAINTAIN_ANY'],
          },
        ],
      },
      {
        title: 'Cadastros',
        path: '/app',
        enable: false,
        subMenus: [
          {
            title: 'Conta interna',
            path: '/app/banking/internal-bank-account',
            enable: true,
            key: 'internal_bank_account',
            grants: ['MAINTAIN', 'GET'],
          },
          {
            title: 'Beneficiário final',
            path: '/app/banking/final-beneficiary',
            enable: true,
            key: 'final_beneficiary',
            grants: ['MAINTAIN', 'GET'],
          },
        ],
      },
      {
        title: 'Boletos',
        path: '/app',
        enable: false,
        subMenus: [
          {
            title: 'Cancelamento',
            path: '/app/banking/bank-slip/cancel',
            enable: false,
            key: 'bank_slip',
            grants: ['PATCH_CANCEL'],
          },
          {
            title: 'Extrato de movimentações',
            path: '/app/banking/bank-slip/movements',
            enable: false,
            key: 'transact_moviments',
            grants: ['GET'],
          },
          {
            title: 'Histórico',
            path: '/app/banking/bank-slip/bank-slip-history',
            enable: false,
            key: 'bank_slip',
            grants: ['GET_ANY'],
          },
          {
            title: 'Validação de movimentações',
            path: '/app/banking/bank-slip/bank-slip-validations',
            enable: false,
            key: 'bank_slip',
            grants: ['GET_ANY'],
          },
        ],
      },
    ],
  },
  {
    title: 'Cadastro Único',
    path: '/app',
    enable: false,
    menus: [
      {
        title: 'Pessoas',
        subMenus: [],
        enable: false,
      },
      {
        title: 'Clientes',
        subMenus: [],
        enable: false,
      },
    ],
  },
];
