import styled, { css } from 'styled-components';

interface GlobalAlertProps {
  width?: string | number;
  height?: string | number;
  $backgroundColor?: string;
  color?: string;
  $showAlert?: boolean;
}

export const Container = styled.div<GlobalAlertProps>`
  ${({ width, height, $backgroundColor, color, $showAlert }) => css`
    position: absolute;
    top: 90vh;
    left: 50%;
    visibility: ${$showAlert ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: ${width ?? 'auto'};
    height: ${height ?? '5vh'};
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
    background-color: ${$backgroundColor ?? '#d9d9d9'};
    color: ${color ?? '#fff'};
    font-weight: bold;

    > p {
      font-size: 1rem;
    }

    > svg {
      &:hover {
        cursor: pointer;
      }
    }
  `}
`;
