import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppHome } from '@components/app-home';
import { Swagger } from '@components/swagger';
import { AuthProvider } from '@context/auth-context';
import { AlertProvider } from '@context/alert-context';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AlertProvider>
        <Router>
          <Routes>
            <Route path="/app/*" Component={AppHome} />
            <Route path="/app/banking/api" Component={Swagger} />
          </Routes>
        </Router>
      </AlertProvider>
    </AuthProvider>
  );
};

export default App;
