import { createContext, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authPermissions, setAuthPermissions] = useState<any>();
  const [payPermissions, setPayPermissions] = useState<any>();

  const hasPermission = (permissions: any, permissionData: any) => {
    return !!permissions[permissionData?.key]?.some((permission: string) =>
      permissionData?.grants?.includes(permission)
    );
  };

  const getAuthenticatedEntity = (token: string) => {
    try {
      const { loginOrigin, lastAccess } = JSON.parse(sessionStorage.getItem('entitySession'));
      const decodedToken: any = jwtDecode(token);
      const names = decodedToken?.fullName.split(' ');
      const nameAbbr = `${names[0]} ${names[names.length - 1]}`;

      return {
        fullName: decodedToken.fullName,
        nameAbbr,
        type: decodedToken.type,
        email: decodedToken.email,
        accessType: loginOrigin,
        lastAccess
      };
    } catch (error) {
      return;
    }
  };

  const updatePermissions = (token: string) => {
    try {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken?.keyList) {
        const application =
          decodedToken.keyList?.core_auth?.entity?.application || [];
        const user = decodedToken.keyList?.core_auth?.entity?.user || [];
        const grantAuth = decodedToken.keyList?.core_auth?.grant || [];
        const group = decodedToken.keyList?.core_auth?.group || [];
        const grantPay = decodedToken.keyList?.core_pay?.grant || [];
        const final_beneficiary =
          decodedToken.keyList?.core_pay?.final_beneficiary || [];
        const internal_bank_account =
          decodedToken.keyList?.core_pay?.internal_bank_account || [];
        const bank_slip = decodedToken.keyList?.core_pay?.bank_slip || [];
        const transact_moviments =
          decodedToken.keyList?.core_pay?.core_banking?.transact_moviments ||
          [];
        const cash_in =
          decodedToken.keyList?.core_pay?.core_banking?.cash_in || [];
        const cash_out =
          decodedToken.keyList?.core_pay?.core_banking?.cash_out || [];

        setAuthPermissions({
          application,
          user,
          core_auth_grant: grantAuth,
          group,
        });

        setPayPermissions({
          core_pay_grant: grantPay,
          final_beneficiary,
          internal_bank_account,
          bank_slip,
          transact_moviments,
          cash_in,
          cash_out,
        });
      } else {
        setAuthPermissions(null);
        setPayPermissions(null);
      }
    } catch (error) {
      console.error('Error decoding or parsing token:', error);
      setAuthPermissions(null);
      setPayPermissions(null);
    }
  };

  const contextValue = {
    permissions: {
      ...authPermissions,
      ...payPermissions,
    },
    hasPermission,
    updatePermissions,
    getAuthenticatedEntity,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
