import React, { useEffect, useState } from 'react';
import { LifeCycles, ParcelConfig } from 'single-spa';
import Parcel from 'single-spa-react/parcel';

export const ParcelBanking: React.FC = () => {
  const [bankingConfig, setBankingConfig] = useState<ParcelConfig | null>(null);
  const [configError, setConfigError] = useState<boolean>(false);

  useEffect(() => {
    System.import<LifeCycles>('@trinus/fe-core-trinus-banking')
      .then((config) => {
        setBankingConfig(config);
      })
      .catch(() => {
        setConfigError(true);
      });
  }, []);

  const renderParcel = () => {
    if (configError) {
      return <div>Funcionalidades de banking indisponíveis!</div>;
    }

    if (bankingConfig) {
      return <Parcel config={bankingConfig} />;
    }

    return <></>;
  };

  return renderParcel();
};
