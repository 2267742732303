import styled, { css } from 'styled-components';

export const Container = styled.div`
  min-width: 15rem;
  max-width: 15rem;
  height: 100%;
  position: relative;
  align-items: center;

  @media (max-width: 27rem) {
    max-width: 13rem;
    min-width: 10rem;
  }
`;

export const ProfileWrapper = styled.div`
  height: 100%;
  padding: 0.5rem;
  border: 1px solid rgb(11, 93, 215, 0.5);
  border-top: none;
  border-right: none;
  border-bottom: none;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const EntityWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 0.2rem;
`;

export const EntityFrame = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(26, 108, 232, 0.8);
`;

export const Info = styled.span<{
  $size: string;
  color?: string;
}>`
  font-size: ${({ $size }) => $size};
  font-family: 'Asap', sans-serif;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${({ $size }) => $size};
  color: ${({ color }) => color};
  margin: 0;
  padding: 0;
`;

export const ButtonWrapper = styled.button<{
  $isActive: boolean;
}>`
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      rotate: 180deg;
    `}
`;
