import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;

  > span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #f00;

    &:hover {
      cursor: pointer;
    }
  }

  > svg {
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1000px) {
    > span {
      display: none;
    }
  }

  @media (max-width: 450px) {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
