import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ItemProps {
  $isClicked?: boolean;
  $isSubMenu?: boolean;
  $enable?: boolean;
  $showSubMenus?: boolean;
}

export const Menu = styled(Link)<ItemProps>`
  ${({ $isClicked, $isSubMenu, $enable, $showSubMenus }) => css`
    display: ${$enable ? 'flex' : 'none'};
    justify-content: space-between;
    align-items: center;
    list-style: none;
    text-decoration: none;
    padding: 1rem;
    padding-left: ${!$isSubMenu ? '1rem' : '1.5rem'};
    height: 3rem;
    font-size: ${!$isSubMenu ? '1rem' : '0.9rem'};
    font-weight: ${$isClicked && !$isSubMenu ? 'bold' : 'normal'};
    color: white;

    > label {
      cursor: ${$enable ? 'pointer' : 'default'};
    }

    &:hover {
      background-color: #0b5dd7;
      border-left: 0.2rem solid #fff;
      cursor: ${$enable ? 'pointer' : 'default'};
      transition: 350ms;
      z-index: 2;
    }
  `}
`;

export const SubMenuItem = styled(Link)<ItemProps>`
  ${({ $enable }) => css`
    display: ${$enable ? 'flex' : 'none'};
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 0.8rem;
    height: 3rem;
    padding-left: 1.5rem;
    background-color: #0b5dd7;

    > label {
      cursor: ${$enable ? 'pointer' : 'default'};
    }

    &:hover {
      font-weight: ${$enable ? 'bold' : 'none'};
      background-color: #1a6ce8;
      border-left: 0.2rem solid #fff;
      transition: 350ms;
      z-index: 2;
      cursor: ${$enable ? 'pointer' : 'default'};
    }
  `}
`;
