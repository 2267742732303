import React from 'react';

interface LogoTrinusSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const LogoTrinusSvg = ({
  color,
  width,
  height,
  viewBox,
  ...props
}: LogoTrinusSvgProps) => {
  return (
    <svg
      {...props}
      justify-content={'center'}
      width={width || '76'}
      height={height || '20'}
      viewBox={viewBox || '0 0 90 20'}
      fill={color || '#fff'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M78.2471 4.81362C78.2471 4.44804 77.943 4.15167 77.5678 4.15167C77.1926 4.15167 76.8885 4.44804 76.8885 4.81362C76.8885 5.17921 77.1926 5.47558 77.5678 5.47558C77.943 5.47558 78.2471 5.17921 78.2471 4.81362Z" />
      <path d="M89.2217 0.796915C88.7073 0.282776 88.0478 0.0257069 87.2431 0.0257069C86.4385 0.0257069 85.779 0.282776 85.2645 0.796915C84.7501 1.31105 84.4863 1.99229 84.4863 2.81491C84.4863 3.63753 84.7501 4.31877 85.2645 4.83291C85.779 5.34704 86.4385 5.60411 87.2431 5.60411C88.0478 5.60411 88.7073 5.34704 89.2217 4.83291C89.7362 4.31877 90 3.63753 90 2.81491C90 1.99229 89.7362 1.31105 89.2217 0.796915ZM88.7996 2.81491C88.7996 3.32905 88.6545 3.75321 88.3643 4.0617C88.0742 4.37018 87.7048 4.52442 87.2563 4.52442C86.8079 4.52442 86.4385 4.37018 86.1483 4.0617C85.8581 3.75321 85.713 3.32905 85.713 2.81491C85.713 2.30077 85.8581 1.87661 86.1483 1.56812C86.4385 1.25964 86.8079 1.1054 87.2563 1.1054C87.7048 1.1054 88.0742 1.25964 88.3643 1.56812C88.6545 1.87661 88.7996 2.30077 88.7996 2.81491Z" />
      <path d="M15.1297 19.3188V5.87404C15.1297 5.65553 15.3012 5.48843 15.5254 5.48843H18.1372L18.4274 6.74807H18.5725C18.744 6.52956 18.955 6.32391 19.2056 6.13111C19.7992 5.70694 20.4456 5.48843 21.1447 5.48843H23.6113C23.8356 5.48843 24.007 5.65553 24.007 5.87404V8.44473C24.007 8.66324 23.8356 8.83034 23.6113 8.83034H21.4349C19.9047 8.83034 18.7176 9.47301 18.7176 11.4782V19.3188C18.7176 19.5373 18.5461 19.7044 18.3219 19.7044H15.5386C15.3144 19.7044 15.1429 19.5373 15.1429 19.3188H15.1297Z" />
      <path d="M32.0006 19.3188V5.87404C32.0006 5.65553 32.1721 5.48843 32.3963 5.48843H35.2851L35.5753 6.87661H35.7204C35.9446 6.59383 36.2744 6.32391 36.6965 6.04113C37.567 5.48843 38.5827 5.20566 39.7303 5.20566C41.3923 5.20566 42.7642 5.75835 43.8326 6.86375C44.9143 7.96915 45.4551 9.40874 45.4551 11.1954V19.3188C45.4551 19.5373 45.2836 19.7044 45.0594 19.7044H42.2761C42.0519 19.7044 41.8804 19.5373 41.8804 19.3188V11.4781C41.8804 10.5656 41.5902 9.8329 41.0098 9.26735C40.4294 8.7018 39.6644 8.41902 38.741 8.41902C37.8177 8.41902 37.0526 8.7018 36.4722 9.26735C35.8918 9.8329 35.6016 10.5784 35.6016 11.4781V19.3188C35.6016 19.5373 35.4302 19.7044 35.2059 19.7044H32.4227C32.1984 19.7044 32.027 19.5373 32.027 19.3188H32.0006Z" />
      <path d="M48.1592 13.9846V5.87404C48.1592 5.65553 48.3306 5.48843 48.5549 5.48843H51.3381C51.5624 5.48843 51.7338 5.65553 51.7338 5.87404V13.7147C51.7338 14.6273 52.024 15.3599 52.6044 15.9255C53.1848 16.491 53.9499 16.7738 54.8732 16.7738C55.7966 16.7738 56.5616 16.491 57.142 15.9255C57.7224 15.3599 58.0126 14.6144 58.0126 13.7147V5.87404C58.0126 5.65553 58.1841 5.48843 58.4083 5.48843H61.1915C61.4158 5.48843 61.5873 5.65553 61.5873 5.87404V19.3188C61.5873 19.5373 61.4158 19.7044 61.1915 19.7044H58.6194C58.4347 19.7044 58.2632 19.5758 58.2368 19.3959L58.0126 18.3162H57.8675C57.6433 18.599 57.3135 18.8689 56.8914 19.1517C56.0076 19.7044 55.0051 19.9871 53.8575 19.9871C52.1955 19.9871 50.8237 19.4344 49.7552 18.3291C48.6736 17.2237 48.1328 15.7841 48.1328 13.9974L48.1592 13.9846Z" />
      <path d="M64.7398 15.3856H67.3912C67.5626 15.3856 67.7209 15.5013 67.7737 15.6684C67.866 16.0026 68.1035 16.3111 68.4992 16.6195C69.0004 17.0051 69.6995 17.2108 70.5965 17.2108C72.4036 17.2108 73.3138 16.6967 73.3138 15.6812C73.3138 15.2314 73.1687 14.8843 72.8785 14.6401C72.5883 14.383 72.0739 14.2031 71.3088 14.1003L69.0136 13.7404C65.861 13.2391 64.2914 11.8895 64.2914 9.69152C64.2914 8.44473 64.8322 7.39075 65.927 6.52956C67.0218 5.66838 68.5256 5.23136 70.4514 5.23136C72.3772 5.23136 73.881 5.7455 74.9758 6.76093C75.9123 7.63496 76.44 8.509 76.5719 9.38303C76.6115 9.6144 76.4136 9.82005 76.1762 9.82005H73.5116C73.327 9.82005 73.1687 9.69152 73.1291 9.52442C73.05 9.17738 72.8125 8.85604 72.4168 8.57327C71.9156 8.20051 71.256 8.02057 70.4382 8.02057C69.6204 8.02057 68.9872 8.18766 68.5387 8.509C68.0903 8.83033 67.866 9.22879 67.866 9.69152C67.866 10.3856 68.5387 10.8226 69.871 11.0026L72.2981 11.3625C75.3451 11.8123 76.8753 13.2519 76.8753 15.6812C76.8753 16.928 76.3344 17.9563 75.2528 18.7789C74.1712 19.6015 72.6147 20 70.5701 20C69.5412 20 68.6311 19.8715 67.8396 19.6144C67.0482 19.3573 66.4414 19.0488 66.0061 18.6761C65.5708 18.3162 65.2279 17.9049 64.9509 17.4293C64.6739 16.9666 64.4892 16.5681 64.4101 16.2468C64.3705 16.1054 64.3441 15.9769 64.3177 15.8483C64.2782 15.617 64.4628 15.3985 64.7135 15.3985L64.7398 15.3856Z" />
      <path d="M28.9008 5.48843H26.1175C25.899 5.48843 25.7218 5.66107 25.7218 5.87404V19.3188C25.7218 19.5317 25.899 19.7044 26.1175 19.7044H28.9008C29.1193 19.7044 29.2965 19.5317 29.2965 19.3188V5.87404C29.2965 5.66107 29.1193 5.48843 28.9008 5.48843Z" />
      <path d="M29.2437 0.989717L27.7136 0.128535C27.5949 0.0642674 27.4366 0.0642674 27.3179 0.128535L25.7878 0.989717C25.6691 1.05398 25.5899 1.18252 25.5899 1.32391V2.98201C25.5899 3.20051 25.7614 3.36761 25.9856 3.36761H29.0591C29.2833 3.36761 29.4548 3.20051 29.4548 2.98201V1.32391C29.4548 1.18252 29.3756 1.05398 29.2569 0.989717H29.2437Z" />
      <path d="M6.00176 3.48329H0.39572C0.171479 3.48329 0 3.3162 0 3.09769V0.385604C0 0.167095 0.171479 0 0.39572 0H15.3408C15.565 0 15.7365 0.167095 15.7365 0.385604V3.09769C15.7365 3.3162 15.565 3.48329 15.3408 3.48329H9.72153V19.2931C9.72153 19.5116 9.55005 19.6787 9.32581 19.6787H6.39748C6.17324 19.6787 6.00176 19.5116 6.00176 19.2931V3.48329Z" />
      <path d="M82.1779 4.22879C81.9537 4.42159 81.6503 4.51157 81.2809 4.51157C80.8325 4.51157 80.4631 4.35733 80.1729 4.04884C79.8827 3.74036 79.7376 3.3162 79.7376 2.80206C79.7376 2.28792 79.8827 1.86375 80.1729 1.55527C80.4631 1.24679 80.8325 1.09255 81.2809 1.09255C82.2439 1.09255 82.5341 1.72237 82.6132 1.95373C82.6264 2.00514 82.6792 2.03085 82.7319 2.03085H83.6948C83.774 2.03085 83.8268 1.95373 83.8136 1.88946C83.7476 1.6581 83.5498 1.15681 83.0221 0.62982C82.6 0.231362 82.0196 0.0257069 81.2941 0.0257069C80.5027 0.0257069 79.83 0.282776 79.3155 0.796915C78.8011 1.31105 78.5373 1.99229 78.5373 2.81491C78.5373 3.63753 78.8011 4.31877 79.3155 4.83291C79.83 5.34704 80.4895 5.60411 81.2941 5.60411C81.6767 5.60411 82.0328 5.53985 82.3494 5.41131C82.666 5.28278 82.9298 5.11568 83.1145 4.91003C83.3123 4.70437 83.4574 4.49871 83.5761 4.28021C83.6816 4.10026 83.7608 3.85604 83.8004 3.7018C83.8267 3.62468 83.7608 3.56041 83.6817 3.56041H82.7187C82.7187 3.56041 82.6264 3.59897 82.6 3.63753C82.5604 3.76607 82.4417 4.04884 82.1647 4.26735L82.1779 4.22879Z" />
    </svg>
  );
};
export default LogoTrinusSvg;
