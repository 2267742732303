import styled from 'styled-components';

export const Container = styled.nav`
  width: 85vw;
  height: 6vh;
  min-height: 2.2rem;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;

  @media (max-width: 66.625rem) {
    width: calc(100vw - 10rem);
  }
`;
