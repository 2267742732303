import React from 'react';

interface LogOutSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

const LogOutSvg = ({
  color,
  width = '20',
  height = '20',
  viewBox,
  ...props
}: LogOutSvgProps) => {
  return (
    <svg
      {...props}
      width={width || '40'}
      height={height || '40'}
      viewBox={viewBox || '0 0 40 40'}
      fill={color || '#606060'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.6797 33H8C7.79167 33 7.58333 32.9609 7.375 32.8828C7.16667 32.7786 6.98438 32.6484 6.82812 32.4922C6.67188 32.3359 6.55469 32.1667 6.47656 31.9844C6.39844 31.776 6.35938 31.5547 6.35938 31.3203V8C6.35938 7.76562 6.39844 7.54427 6.47656 7.33594C6.55469 7.1276 6.67188 6.95833 6.82812 6.82812C6.98438 6.67188 7.16667 6.55469 7.375 6.47656C7.58333 6.3724 7.79167 6.32031 8 6.32031H14.6797C15.1484 6.32031 15.5391 6.16406 15.8516 5.85156C16.1901 5.51302 16.3594 5.10938 16.3594 4.64062C16.3594 4.19792 16.1901 3.82031 15.8516 3.50781C15.5391 3.16927 15.1484 3 14.6797 3H8C7.32292 3 6.67188 3.13021 6.04688 3.39062C5.44792 3.65104 4.92708 4.0026 4.48438 4.44531C4.04167 4.91406 3.67708 5.44792 3.39062 6.04688C3.13021 6.64583 3 7.29688 3 8V31.3203C3 31.9974 3.13021 32.6484 3.39062 33.2734C3.65104 33.8724 4.01562 34.3932 4.48438 34.8359C4.92708 35.3047 5.44792 35.6693 6.04688 35.9297C6.67188 36.1901 7.32292 36.3203 8 36.3203H14.6797C15.1484 36.3203 15.5391 36.1641 15.8516 35.8516C16.1901 35.513 16.3594 35.1094 16.3594 34.6406C16.3594 34.1979 16.1901 33.8203 15.8516 33.5078C15.5391 33.1693 15.1484 33 14.6797 33ZM30.6562 18H14.6797C14.2109 18 13.8073 18.1693 13.4688 18.5078C13.1562 18.8203 13 19.1979 13 19.6406C13 20.1094 13.1562 20.513 13.4688 20.8516C13.8073 21.1641 14.2109 21.3203 14.6797 21.3203H30.6562L25.1875 26.8281C24.849 27.1406 24.6797 27.5312 24.6797 28C24.6797 28.4427 24.849 28.8333 25.1875 29.1719C25.5 29.4844 25.8776 29.6406 26.3203 29.6406C26.7891 29.6406 27.1927 29.4844 27.5312 29.1719L35.8516 20.8125C35.9297 20.7344 35.9948 20.6562 36.0469 20.5781C36.125 20.474 36.1771 20.3828 36.2031 20.3047C36.3333 19.9922 36.3724 19.6797 36.3203 19.3672C36.2682 19.0286 36.112 18.7292 35.8516 18.4688L27.5312 10.1484C27.1927 9.8099 26.7891 9.64062 26.3203 9.64062C25.8776 9.64062 25.5 9.8099 25.1875 10.1484C24.849 10.4609 24.6797 10.8516 24.6797 11.3203C24.6797 11.7891 24.849 12.1797 25.1875 12.4922L30.6562 18Z" />
    </svg>
  );
};

export default LogOutSvg;
