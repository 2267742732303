import styled from 'styled-components';

export const Container = styled.aside`
  min-width: 10rem;
  height: 100vh;
  width: 15vw;
  display: flex;
  flex-direction: column;
  background-color: #1a6ce8;
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 0.2rem;

  > h1 {
    color: #fff;
    font-size: 1.5rem;
  }
`;

export const ItemsSection = styled.div`
  overflow: auto;
  height: 90vh;
`;

export const SwaggerSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 0.2rem;

  > h4 {
    cursor: pointer;
    color: #fff;
    font-size: 0.8rem;
    text-decoration: underline;
  }
`;
