import { FC, useEffect, useState } from 'react';
import {
  ButtonWrapper,
  Container,
  EntityFrame,
  EntityWrapper,
  Info,
  ProfileWrapper,
} from './styles';
import { useAuth } from '@context/auth-context';
import { FaRegUser as UserIcon } from 'react-icons/fa';
import { MdOutlineJoinLeft as ApplicationIcon } from 'react-icons/md';
import { IoIosArrowDown as ArrowDown } from 'react-icons/io';
import { ProfileData } from './profile-data';

interface authenticatedEntity {
  fullName: string;
  nameAbbr: string;
  type: string;
  email?: string;
  accessType: string;
  lastAccess: string;
}

export const LoggedProfile: FC = () => {
  const authContext = useAuth();
  const [showInfo, setShowInfo] = useState(false);
  const [authenticatedEntity, setAuthenticatedEntity] =
    useState<authenticatedEntity>();
  const { token } = JSON.parse(sessionStorage.getItem('entitySession'));

  useEffect(() => {
    const authenticatedEntityData = authContext.getAuthenticatedEntity(token);
    setAuthenticatedEntity(authenticatedEntityData);
  }, [token]);

  const camelCase = (str: string) => {
    let words = str.split(' ');
    if (words.length > 1) {
      words = words.map((word) => {
        const lowerWord: any = word.toLowerCase();

        let result = lowerWord.charAt(0).toUpperCase();
        result += lowerWord.substr(1, lowerWord.length - 1);

        return result;
      });
    }

    return words.join(' ');
  };

  const renderEntityFrame = () => {
    if (authenticatedEntity?.type === 'USE') {
      return <UserIcon fill={'#fff'} size={'1rem'} />;
    }
    return <ApplicationIcon fill={'#fff'} size={'1rem'} />;
  };

  const handleShowInfo = () => {
    setShowInfo((oldValue) => !oldValue);
  };

  return (
    <>
      <Container>
        <ProfileWrapper onClick={handleShowInfo}>
          <EntityFrame>{renderEntityFrame()}</EntityFrame>
          <EntityWrapper>
            <Info
              $size={'0.85rem'}
            >
              {camelCase(authenticatedEntity?.nameAbbr || '')}
            </Info>
            <Info $size={'0.6rem'} color='#606060'>Último acesso: {authenticatedEntity?.lastAccess}</Info>
          </EntityWrapper>
          <ButtonWrapper
            data-testid={'show-info-profile'}
            $isActive={showInfo}
          >
            <ArrowDown fill={'#1a6ce8'} size={'1rem'} />
          </ButtonWrapper>
        </ProfileWrapper>
        {showInfo &&
          <ProfileData
            fullName={camelCase(authenticatedEntity?.fullName)}
            email={authenticatedEntity?.email ?? ''}
            accessType={authenticatedEntity?.accessType ? 'Login corporativo' : 'Usuário/Senha'}
          />
        }
      </Container>
    </>
  );
};
